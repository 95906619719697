.Footer-module__hideFooterWithoutAuthMsg {
  padding: 0 !important;
  margin: 0 !important;
  border-top: none !important;
}

footer {
  border-top: solid 1px #d8d8d8;
  margin-top: 50px;
  padding-top: 50px;
}
.Footer-module__dataFooterContent {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #697a8f;
  font-size: 12px;
  margin-top: 20px;
}

.Footer-module__dataFooterContent a {
  color: #697a8f;
}

.Footer-module__dataFooterContent ul a {
  font-size: 14px;
  margin: 0 10px;
}

.Footer-module__dataFooterContent ul {
  display: flex;
  align-items: center;
}
.Footer-module__dataFooterContent ul.Footer-module__payment {
  flex-wrap: wrap;
  width: 65%;
  height: auto;
}
.Footer-module__dataFooterContent ul > li {
  margin: 0 5px;
}

.Footer-module__dataFooterContent > div > * {
  margin-bottom: 25px;
  height: 15px;
}
.Footer-module__webview footer.Footer-module__footer {
  display: none !important;
}
/* footer special case Pages */
.Footer-module__CheckOut + footer.Footer-module__footer {
  border: none;
}

.Footer-module__CheckOut + footer.Footer-module__footer > .Footer-module__container {
  display: none !important;
}


/* vatIcon */
.Footer-module__footerWithVat {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 265px;
}

.Footer-module__footerWithVat > img {
  max-width: 200px;
  width: 100%;
}

.Footer-module__footerWithVat > .Footer-module__vatLink {
    display: inline-flex;
    align-items: center;
}

a.Footer-module__vatLink:after {
  content: "";
  width: 2px;
  height: 32px;
  background-color: #b2bcc2;
  display: inline-block;
  margin: 0 15px;
  position: relative;
  top: -2px;
}



.Footer-module__authenticationMsg {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #e8f6fe;
  color: #484848;
  padding: 4px 10px;
  z-index: 999;
  box-sizing: border-box;
}

.Footer-module__authenticationMsg > .Footer-module__container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Footer-module__authenticationMsg > .Footer-module__container > img {
  max-width: 200px;
}

.Footer-module__authenticationMsg > .Footer-module__container > p {
  font-size: 14px;
  margin: 0;
}

.Footer-module__authenticationMsg > .Footer-module__container > p > span.Footer-module__icon-greenArmor {
  vertical-align: middle;
  margin: 0 5px;
}
